import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Box } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TearSheetTemplate from './templates/templates';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getApi, postApi, putApi, deleteApi } from '../../../services/token';
import { setTearSheetData, setTearSheetKeys } from '../action';
import { createTemplate } from './tearSheetTemplateMocks';
import { LinearProgress } from '@mui/material';
import ErrorToast from '../../atoms/errorToast';
import SuccessToast from '../../atoms/successToast';
import AddEntitiesPopOver from '../../molecules/addEntitiesPopUp';
import { updateParamsInURL } from '../../../helpers';
import TearSheetSearchBar from './templateSearchBar';
import { ReactComponent as LeftArrowBlack } from '../../../components/icons/LeftArrowBlack.svg';
import DeletePopOver from '../../atoms/delete-dialog';
import { getNameFunc } from '../utils/usernameFunc';
import { AuthContext } from '../../auth/contexts/authContext';

const useStyles = makeStyles(() => ({
  templateHeading: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '22px !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '30px !important',
    cursor: 'pointer',
    padding: '10px 0px'
  },
  templateHeadingDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  selected: {
    padding: '6px 8px !important',
    borderRadius: '4px !important',
    border: '1px solid #E4EBFC !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    textTransform: 'none !important'
  },
  unSelected: {
    padding: '6px 8px !important',
    borderRadius: '4px !important',
    border: '1px solid #FFFFFF !important',
    backgroundColor: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  selectedText: {
    fontFamily: 'Satoshi !important',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '700 !important'
  },
  unSelectedText: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '16px',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '700 !important'
  },
  emptyTemplate: {
    display: 'flex',
    height: '44px',
    padding: '10px 32px',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '0px 0px 12px 0px rgba(6, 10, 84, 0.08)',
    alignItems: 'center'
  },
  emptyTemplateTxt: {
    fontFamily: 'Satoshi !important',
    color: '#171717 !important',
    fontSize: '18px',
    lineHeight: '24px !important',
    fontWeight: '500 !important',
    margin: '0 auto'
  },
  createBtnTxt: {
    fontFamily: 'Satoshi !important',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '500 !important'
  }
}));

const MyTemplates = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const currentPage = props?.currentRoute;
  const [searchParams] = useSearchParams();
  const entity_type = searchParams.get('entity_type');
  const entity_id = searchParams.get('entity_id');
  let templateId = searchParams.get('template_id') || '';
  const refetchTemplateOptions = useRef(null);
  const companyUrl = process.env.REACT_APP_COMPANY_API;
  const adminTemplateEditList = (process.env.REACT_APP_ENABLE_ADMIN_TEMPLATE_EDIT || '').split(',');

  const companyData = useSelector((state) => state.tokenReducer.tearSheetData);
  const templateList = useSelector((state) => state.tokenReducer.tearSheetTemplateOptions);
  const [templateData, setTemplateData] = useState();
  const [initialTemplateData, setInitialTemplateData] = useState();
  const [openPoper, setOpenPoper] = useState(false);
  const [triggerSelectedCompanies, setTriggerSelectedCompanies] = useState([]);
  const [optionsEntityType, setOptionsEntityType] = useState('all');
  const tearSheetKeysRedux = useSelector((state) => state.tokenReducer.tearSheetKeys);
  const [selectedFilter, setSelectedFilter] = useState(entity_type || 'company');
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [poperSuccessMessage, setPoperSuccessMessage] = useState('');
  const [triggerError, setTriggerErrorMessage] = useState('');
  const [tearSheetStatus, setTearSheetStatus] = useState('');
  const [templateValidationErrors, setTemplateValidationErrors] = useState({});
  const auth = useContext(AuthContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleManageTearsheetTemplate = (event) => {
    navigate(`/my-templates${event.target.value === 'new' ? '?template_id=new' : ''}`);
  };

  const getTearSheetData = async () => {
    if (!entity_id || !templateId) {
      dispatch(setTearSheetData(null));
      return;
    } else {
      try {
        const res = await getApi(`${companyUrl}/v2/company_tear_sheet`, {
          entity_id: entity_id,
          entity_type: entity_type,
          template_id: templateId
        });
        const data = res.data.data;
        dispatch(setTearSheetData(data));
      } catch (err) {
        dispatch(setTearSheetData(null));
        setApiError(t('tearSheet.apiError'));
        setTimeout(() => setApiError(''), 10000);
      }
    }
  };
  const getTemplateData = async () => {
    if (!templateId) {
      return;
    } else if (templateId === 'new') {
      setInitialTemplateData(JSON.parse(JSON.stringify({ ...createTemplate })));
      let duplicatedTemplate = location?.state?.duplicatedTemplate;
      if (duplicatedTemplate) {
        setTemplateData(duplicatedTemplate);
      } else {
        setTemplateData(JSON.parse(JSON.stringify({ ...createTemplate })));
      }
    } else {
      try {
        const res = await getApi(`${companyUrl}/v2/template/${templateId}`);
        const data = res.data.data;
        setTemplateData(data);
        setInitialTemplateData(JSON.parse(JSON.stringify({ ...data })));
      } catch (err) {
        setTemplateData(null);
        setInitialTemplateData(null);
        setApiError(t('tearSheet.apiError'));
        setTimeout(() => setApiError(''), 10000);
      }
    }
  };

  const handleCompanyChange = (event, value) => {
    if (value === null) {
      dispatch(setTearSheetKeys({}));
      dispatch(setTearSheetData(null));
      navigate(`/report`);
    } else {
      navigate(
        `/report?entity_type=${value?.entity_type}&entity_id=${value?.entity_id}${
          templateId ? `&template_id=${templateId}` : ''
        }`
      );
    }
  };
  const handleTemplateChange = (event) => {
    if (currentPage === t('tearSheet.templateRoute')) {
      navigate(`/template?template_id=${event.target.value}`);
    } else {
      dispatch(setTearSheetKeys({}));
      navigate(
        `/tearsheet?${
          entity_id ? `entity_type=${entity_type}&entity_id=${entity_id}&` : ''
        }template_id=${event.target.value}`
      );
    }
  };

  const handleManageTemplate = (event) => {
    dispatch(setTearSheetKeys({ ...tearSheetKeysRedux }));
    navigate(
      `/template${
        event.target.value === 'new'
          ? '?template_id=new'
          : templateId
          ? `?template_id=${templateId}`
          : ''
      }`
    );
  };

  const handleDuplicate = () => {
    let duplicatedTemplate = {};
    duplicatedTemplate.template_name = initialTemplateData.template_name + ' - Updated';
    duplicatedTemplate.description = initialTemplateData.description;
    duplicatedTemplate.question_groups = initialTemplateData.question_groups;
    navigate('/my-templates?template_id=new', {
      state: { duplicatedTemplate: duplicatedTemplate }
    });
  };
  const modifyErrors = (modifiedValidationErrors) => {
    let question_groups_errors = modifiedValidationErrors?.question_groups;
    if (question_groups_errors?.length) {
      question_groups_errors.sort((a, b) => a.index - b.index);
      for (let i = 0; i < templateData?.question_groups.length; i++) {
        if (question_groups_errors.length <= i) {
          question_groups_errors.push({});
        } else if (i !== question_groups_errors[i]?.index) {
          question_groups_errors.splice(i, 0, {});
        }
        let questions_errors = question_groups_errors[i]?.questions;
        if (questions_errors) {
          questions_errors.sort((a, b) => a.index - b.index);
          for (let j = 0; j < templateData?.question_groups?.[i]?.questions?.length; j++) {
            if (questions_errors.length <= j) {
              questions_errors.push({});
            } else if (j !== questions_errors?.[j]?.index) {
              questions_errors.splice(j, 0, {});
            }
          }
        }
      }
    }
  };

  const handleSaveTemplate = async () => {
    setLoading(true);
    setPoperSuccessMessage('');
    setApiError('');
    setTemplateValidationErrors(null);

    window.scrollTo({ top: 0, behavior: 'smooth' });
    const endpoint =
      templateId === 'new'
        ? `${companyUrl}/v2/template`
        : `${companyUrl}/v2/template/${templateId}`;

    const method = templateId === 'new' ? postApi : putApi;
    let payload = { ...templateData, entity_type: selectedFilter, template_type: 'user_defined' };
    delete payload.company_list;
    try {
      const res = await method(endpoint, payload);

      if (res.data?.data.success === false) {
        let modifiedValidationErrors = res.data?.data?.errors;
        modifyErrors(modifiedValidationErrors);
        setTemplateValidationErrors(modifiedValidationErrors);
        setApiError(t('tearSheet.templateValidationError'));
        setTimeout(() => setApiError(''), 10000);
        setLoading(false);
        return;
      }

      if (res.data?.data.success === true) {
        dispatch(setTearSheetKeys({}));
        const successMessage =
          templateId === 'new'
            ? t('tearSheet.templateCreateSuccessful')
            : t('tearSheet.templateSaveSuccessful');
        setPoperSuccessMessage(successMessage);

        setTimeout(async () => {
          setLoading(false);
          setPoperSuccessMessage('');
          if (res.data?.data?.template_id) {
            navigate(
              `/my-templates?entity_type=${selectedFilter}&template_id=${res.data?.data?.template_id}`
            );
          }
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setApiError(t('tearSheet.apiError'));
      setTimeout(() => setApiError(''), 10000);
    }
  };

  const handleDiscardTemplate = () => {
    navigate(`/my-templates`, { state: { successMessage: t('tearSheet.templateDiscardText') } });
  };

  const handleDeleteTemplate = async () => {
    setTriggerErrorMessage('');
    setPoperSuccessMessage('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoading(true);
    try {
      const res = await deleteApi(`${companyUrl}/v2/template/${templateId}`);
      console.log(JSON.stringify(res));
      console.log(res?.data?.data?.success);
      if (res?.data?.data?.success === false) {
        if (res.data.data.error.depend_reports?.length > 0) {
          const reportNames = res.data.data.error.depend_reports.map((report, index) => (
            <React.Fragment key={report.report_name}>
              {index > 0 && ', '}
              <a
                target="_blank"
                href={`report?search=${encodeURIComponent(report.entity_name)}&report_id=${
                  report.report_id
                }`}
                style={{ color: '#007bff', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                {report.report_name}
              </a>
            </React.Fragment>
          ));

          const jsxMessage = (
            <>
              {res.data.data.error.message}.
              <br />
              List of report names: {reportNames}
            </>
          );
          setApiError();
          setTriggerErrorMessage(jsxMessage);
          setTimeout(() => setTriggerErrorMessage(''), 10000);
          setLoading(false);
        }
      } else if (res?.data?.data?.success === true) {
        setPoperSuccessMessage(t('tearSheet.tearSheetsDeletion'));
        setTimeout(async () => {
          setLoading(false);
          setPoperSuccessMessage('');
          if (refetchTemplateOptions?.current) {
            await refetchTemplateOptions.current();
          }
          navigate(`/my-templates?entity_type=${selectedFilter}`);
        }, 1000);
      }
    } catch (err) {
      setLoading(false);
      setTriggerErrorMessage(t('tearSheet.triggerFailedDeleteTemplate'));
      setTimeout(() => setTriggerErrorMessage(''), 10000);
    }
    handleCloseDeletePopOver();
  };

  const handlePoperSubmit = async () => {
    if (triggerSelectedCompanies?.length == 0) {
      return;
    }
    setTriggerErrorMessage('');
    setLoading(true);
    try {
      const payload = {
        template_id: templateId,
        entity_list: triggerSelectedCompanies
      };
      await postApi(`${companyUrl}/v2/company_tear_sheet`, payload);
      handleClosePoper();
      navigate(`/tearsheet?template_id=${templateId}`, {
        state: { successMessage: t('tearSheet.tearSheetsRequested') }
      });
    } catch (err) {
      const errorStatus = err?.response?.status;
      if (errorStatus === 403 || errorStatus === 402) {
        setTriggerErrorMessage(t('tearSheet.triggerLimitError'));
      } else {
        setTriggerErrorMessage(t('tearSheet.triggerFailedMessage'));
      }
      console.log(err);
    }
    setLoading(false);
  };

  const handleClosePoper = () => {
    setTriggerErrorMessage('');
    setTriggerSelectedCompanies([]);
    setOpenPoper(false);
  };

  const handlePoperSearchChange = (event, value) => {
    setPoperSuccessMessage('');
    setTriggerErrorMessage('');
    setTriggerSelectedCompanies(value);
  };

  const handlePoperDeleteChange = (key) => {
    setTriggerErrorMessage('');
    setTriggerSelectedCompanies((prev) => prev.filter((option) => option.entity_id !== key));
  };

  const initialise = () => {
    setApiError('');
    // setCompanyData(null);
    setOptionsEntityType('all');
    setTemplateData(null);
    setInitialTemplateData(null);
    setTemplateValidationErrors(null);
    setOpenPoper(location?.state?.openPopUp || false);
    if (location?.state?.setPoperSuccessMessage && !setPoperSuccessMessage) {
      setPoperSuccessMessage(location?.state?.successMessage);
      setTimeout(() => setPoperSuccessMessage(''), 15000);
    } else {
      setPoperSuccessMessage('');
    }
    setTriggerErrorMessage('');
  };

  const getAllData = async () => {
    if (currentPage === t('tearSheet.tearsheetRoute')) {
      // await Promise.all([getTemplateList(), getTearSheetData()]);
      await Promise.all([getTearSheetData()]);
    } else {
      // await Promise.all([getTemplateList(), getTemplateData()]);
      await Promise.all([getTemplateData()]);
    }
  };

  const handleRedux = async () => {
    if (currentPage === t('tearSheet.tearsheetRoute')) {
      const reduxEntityId = tearSheetKeysRedux?.entity_id;
      const reduxEntityType = tearSheetKeysRedux?.entity_type;
      const reduxTemplateId = tearSheetKeysRedux?.templateId;
      if (!entity_id && !templateId && currentPage === t('tearSheet.tearsheetRoute')) {
        if ((reduxEntityId || reduxTemplateId) && reduxTemplateId) {
          navigate(
            `/tearsheet?${
              reduxEntityId ? `entity_id=${reduxEntityId}&entity_type=${reduxEntityType}&` : ''
            }template_id=${reduxTemplateId}`
          );
          dispatch(setTearSheetKeys({ ...tearSheetKeysRedux, lists_updated: false }));
          return;
        } else {
          navigate(`/tearsheet?template_id=${default_temp_id}`);
        }
      }
      if (reduxEntityId !== entity_id || reduxTemplateId !== templateId) {
        dispatch(
          setTearSheetKeys({
            entity_id: entity_id,
            entity_type: entity_type,
            templateId: templateId || default_temp_id
          })
        );
        await getAllData();
      }
    } else {
      await getAllData();
    }
  };

  useEffect(() => {
    const initial = async () => {
      setLoading(true);
      initialise();
      await handleRedux();
      await fetchStatus();
      setLoading(false);
    };
    initial();
  }, [entity_id, templateId, currentPage]);
  useEffect(() => {
    let id;
    if (
      tearSheetStatus === t('tearSheet.inProgress') ||
      tearSheetStatus === t('tearSheet.inQueue')
    ) {
      id = setInterval(fetchStatus, 60000);
    }
    return () => clearInterval(id);
  }, [tearSheetStatus]);

  useEffect(() => {
    const getEmail = async () => {
      const { emailVal } = await getNameFunc(auth);
      const isAdminUser = adminTemplateEditList.includes(emailVal.trim());
      setIsAdminUser(isAdminUser);
    };
    getEmail();
  }, []);

  const fetchStatus = async () => {
    if (!entity_id || !templateId) {
      setTearSheetStatus('');
      return;
    }
    try {
      const queryParams = {
        template_id: templateId,
        entity_type: entity_type,
        entity_id: entity_id
      };
      const res = await getApi(`${companyUrl}/v2/company_tear_sheet/status`, queryParams);
      const status = res.data.data.job_status;
      setTearSheetStatus(status);
      if (status !== t('tearSheet.inProgress') || tearSheetStatus === t('tearSheet.inQueue')) {
        if (status === t('tearSheet.completed')) {
          setLoading(true);
          await getTearSheetData();
          setLoading(false);
        } else if (status === t('tearSheet.failed')) {
          setTriggerErrorMessage(t('tearSheet.triggerFailedMessage'));
          if (companyData?.response && Object.keys(companyData?.response).length > 0) {
            setTimeout(() => setTriggerErrorMessage(''), 10000);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching status:', error);
    }
  };

  const popOverProps = {
    openState: openPoper,
    title: t('tearsheet.requestPoperTitle'),
    handleClose: handleClosePoper,
    selectedValues: triggerSelectedCompanies,
    handleSubmit: handlePoperSubmit,
    // options: onlyCompany
    //   ? templateData?.entity_list?.filter(
    //       (option) => getNestedValue(option, 'entity_type') === 'company'
    //     )
    //   : templateData?.entity_list,
    options: templateData?.entity_list,
    handleSearchChange: handlePoperSearchChange,
    handleDeleteChange: handlePoperDeleteChange,
    optionFormat: {
      key: 'entity_id',
      value: 'entity_name',
      chipValue: 'entity_type'
    },
    btnText: t('tearSheet.requestPoperBtn'),
    successMessage: poperSuccessMessage,
    errorMessage: triggerError,
    setFilterEntityType: setOptionsEntityType,
    filterEntityType: optionsEntityType
  };
  const default_temp_id = process.env.REACT_APP_DEFAULT_TEMPLATE_ID;
  console.log(handleCompanyChange, handleTemplateChange, handleManageTemplate);

  const tearSheetSearchBarProps = {
    t,
    selectedFilter,
    location,
    navigate,
    updateParamsInURL,
    searchParams,
    templateId,
    refetchTemplateOptions
  };

  const handleFilterChange = (newFilter) => {
    if (newFilter) {
      setSelectedFilter(newFilter);
      updateParamsInURL({
        navigate,
        location,
        addParams: { entity_type: newFilter },
        isNew: true
      });
    }
  };
  const [openDeletePopOver, setOpenDeletePopOver] = useState(false);

  const handleCloseDeletePopOver = () => {
    setOpenDeletePopOver(false);
  };

  const handleOpenDeletePopOver = () => {
    setOpenDeletePopOver(true);
  };

  return (
    <div style={{ minHeight: 'calc(100vh - 120px)' }}>
      <DeletePopOver
        content={'Are you sure you want to delete this template. This will be deleted permanently.'}
        openState={openDeletePopOver}
        trigger={handleDeleteTemplate}
        handleClose={handleCloseDeletePopOver}
      />
      {loading && <LinearProgress />}
      <ErrorToast errorMessage={apiError} />
      <SuccessToast successMessage={poperSuccessMessage} />
      <ErrorToast errorMessage={triggerError} />
      <AddEntitiesPopOver {...popOverProps} />
      {!(templateId === 'new') && (
        <div style={{ background: '#F7F9FD' }}>
          <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
            <Grid style={{ padding: '16px 40px' }}>
              <div className={classes.templateHeadingDiv}>
                <Typography className={classes.templateHeading}>My Templates</Typography>
              </div>
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <Button
                  className={selectedFilter === 'company' ? classes.selected : classes.unSelected}
                  onClick={() => handleFilterChange('company')}
                >
                  <Typography
                    className={
                      selectedFilter === 'company' ? classes.selectedText : classes.unSelectedText
                    }
                  >
                    {' '}
                    Company{' '}
                  </Typography>
                </Button>
                <Button
                  className={selectedFilter === 'theme' ? classes.selected : classes.unSelected}
                  onClick={() => handleFilterChange('theme')}
                >
                  <Typography
                    className={
                      selectedFilter === 'theme' ? classes.selectedText : classes.unSelectedText
                    }
                  >
                    {' '}
                    Theme{' '}
                  </Typography>
                </Button>
              </Box>
              <div style={{ padding: '10px 0px' }} />
              <div
                style={{ padding: '10px 0px', display: 'flex', justifyContent: 'space-between' }}
              >
                <TearSheetSearchBar {...tearSheetSearchBarProps} />
                <div>
                  <Button
                    className={classes.selected}
                    style={{ width: '230px' }}
                    onClick={() => handleManageTearsheetTemplate({ target: { value: 'new' } })}
                  >
                    <Typography className={classes.createBtnTxt}> Create New Template </Typography>
                  </Button>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      )}
      {!selectedFilter === null && (
        <Grid style={{ padding: '16px 40px' }}>
          <div className={classes.emptyTemplate}>
            <Typography className={classes.emptyTemplateTxt}>
              Kindly select a template to edit or create new template.
            </Typography>
          </div>
        </Grid>
      )}

      <div style={{ background: '#F7F9FD' }}>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <Grid style={{ padding: '16px 40px' }}>
            <div className={classes.tearSheetDiv}>
              {currentPage === t('tearSheet.tearsheetRoute') ? (
                <></>
              ) : (
                <>
                  <div
                    style={{
                      display: templateId === 'new' ? 'flex' : 'none',
                      gap: '14px',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ cursor: 'pointer' }} onClick={() => window.history.back()}>
                      <LeftArrowBlack style={{ fontSize: '20px' }} />
                    </div>
                    <Typography className={classes.templateHeading}>
                      My Templates - Create New
                    </Typography>
                  </div>
                  <TearSheetTemplate
                    template={templateData}
                    initialTemplate={initialTemplateData}
                    setTemplate={setTemplateData}
                    handleSaveTemplate={handleSaveTemplate}
                    handleDiscardTemplate={handleDiscardTemplate}
                    handleDeleteTemplate={handleOpenDeletePopOver}
                    validationErrors={templateValidationErrors}
                    numOfTemplates={templateList.length}
                    setValidationErrors={setTemplateValidationErrors}
                    // disabled={
                    //   templateList?.find((template) => template.template_id === templateId)
                    //     ?.template_type === 'pre_defined'
                    // }
                    // disabled={['pre_defined'].includes(
                    //   templateList?.find((template) => template.template_id === templateId)
                    //     ?.template_type
                    // )}
                    disabled={
                      !isAdminUser &&
                      ['pre_defined'].includes(
                        templateList?.find((template) => template.template_id === templateId)
                          ?.template_type
                      )
                    }
                    duplicateIcon={['user_defined', 'pre_defined'].includes(
                      templateList?.find((template) => template.template_id === templateId)
                        ?.template_type
                    )}
                    // userDefined={
                    //   templateList?.find((template) => template.template_id === templateId)
                    //     ?.template_type === 'user_defined'
                    //     ? true
                    //     : false
                    // }
                    userDefined={
                      isAdminUser ||
                      templateList?.find((template) => template.template_id === templateId)
                        ?.template_type === 'user_defined'
                    }
                    handleDuplicate={handleDuplicate}
                  />
                </>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default MyTemplates;
