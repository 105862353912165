import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

function Row(props) {
  let { row, isChild, classes, truncateStr } = props;
  const [open, setOpen] = React.useState(false);
  // const icChildExist = row.history && row.history.length > 0;
  let children = [];
  let childTitle = '';
  if (isChild) {
    Object.keys(row).forEach((x) => {
      if (Array.isArray(row[x])) {
        childTitle = x;
        children = row[x];
      }
    });
  }
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {isChild ? (
          <TableCell style={{ borderBottom: 'none' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        {row &&
          Object.keys(row).length > 0 &&
          Object.keys(row)
            .filter((x) => !row[x] || typeof row[x] != 'object')
            .map((x, i) => {
              if (i == 0)
                return (
                  <TableCell
                    style={{
                      borderBottom: 'none',
                      paddingLeft: '0px ',
                      paddingTop: '10px',
                      paddingRight: '12px',
                      paddingBottom: '0px',
                      verticalAlign: 'top'
                    }}
                    title={row[x]}
                    className={classes.fontSize12}
                    component="th"
                    scope="row"
                    key={i}
                  >
                    {/* {truncateStr(row[x], 11)} */}
                    <Typography style={{ fontFamily: 'Satoshi', fontSize: '12px' }}>
                      {`${row[x]}`}
                      {'Linkedin' in row || 'LinkedIn' in row ? (
                        <span>
                          <Link target="_blank" href={row['Linkedin'] || row['LinkedIn']}>
                            <img
                              src="/images/icons/Linkedin - Icon.svg"
                              alt=""
                              style={{ height: '11px', marginLeft: '5px' }}
                            />
                          </Link>
                        </span>
                      ) : null}
                    </Typography>
                  </TableCell>
                );

              return (
                x !== 'Linkedin' &&
                x !== 'LinkedIn' && (
                  <TableCell
                    style={{
                      borderBottom: 'none',
                      paddingLeft: '0px',
                      paddingTop: '10px',
                      paddingRight: '12px',
                      paddingBottom: '0px',
                      verticalAlign: 'top'
                    }}
                    title={row[x]}
                    className={classes.fontSize12}
                    key={i}
                  >
                    {/* {truncateStr(row[x], 11)} */}
                    <Typography style={{ fontFamily: 'Satoshi', fontSize: '12px' }}>
                      {row[x]}
                    </Typography>
                  </TableCell>
                )
              );
            })}
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.fontSize12}
          style={{ borderBottom: 'none', paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {isChild ? (
              <Box sx={{ margin: 1 }}>
                <Typography
                  className={classes.fontSize12}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {childTitle}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {children.length > 0 &&
                        Object.keys(children[0]).length > 0 &&
                        Object.keys(children[0]).map((x, i) => {
                          if (i < 2)
                            return (
                              <TableCell
                                style={{ borderBottom: 'none' }}
                                title={x}
                                className={classes.fontSize12}
                                key={i}
                              >
                                {truncateStr(x, 11)}
                              </TableCell>
                            );

                          return (
                            <TableCell
                              style={{ borderBottom: 'none' }}
                              title={x}
                              className={classes.fontSize12}
                              key={i}
                            >
                              {truncateStr(x, 11)}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {children.length > 0 &&
                      children.map((childRow, index) => {
                        return (
                          <TableRow key={index}>
                            {Object.keys(childRow).length > 0 &&
                              Object.keys(childRow).map((x, i) => {
                                if (i == 0)
                                  return (
                                    <TableCell
                                      style={{ borderBottom: 'none' }}
                                      className={classes.fontSize12}
                                      component="th"
                                      scope="row"
                                      key={i}
                                      title={childRow[x]}
                                    >
                                      {truncateStr(childRow[x], 11)}
                                    </TableCell>
                                  );

                                if (i < 2)
                                  return (
                                    <TableCell
                                      style={{ borderBottom: 'none' }}
                                      className={classes.fontSize12}
                                      key={i}
                                      title={childRow[x]}
                                    >
                                      {truncateStr(childRow[x], 11)}
                                    </TableCell>
                                  );

                                return (
                                  <TableCell
                                    style={{ borderBottom: 'none' }}
                                    className={classes.fontSize12}
                                    key={i}
                                    title={childRow[x]}
                                  >
                                    {truncateStr(childRow[x], 11)}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ config }) {
  const { isChild, tableAccordianData, classes, truncateStr } = config;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      style={{
        borderRadius: '8px',
        backgroundColor: '#FAFAFA'
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {isChild ? <TableCell style={{ borderBottom: 'none' }} /> : null}
            {tableAccordianData &&
              tableAccordianData[0] &&
              Object.keys(tableAccordianData[0]).length > 0 &&
              Object.keys(tableAccordianData[0]).map((x, i) => {
                if (i == 0)
                  return (
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '0px ',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        verticalAlign: 'top'
                      }}
                      title={x}
                      className={classes.fontSize12}
                      key={i}
                    >
                      {/* {truncateStr(x, 11)} */}
                      {x}
                    </TableCell>
                  );

                return (
                  x !== 'Linkedin' &&
                  x !== 'LinkedIn' && (
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '0px ',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        verticalAlign: 'top'
                      }}
                      key={i}
                      title={x}
                      className={classes.fontSize12}
                    >
                      {/* {truncateStr(x, 11)} */}
                      {x}
                    </TableCell>
                  )
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableAccordianData &&
            tableAccordianData.length > 0 &&
            tableAccordianData.map((row, i) => (
              <Row
                key={i}
                row={row}
                isChild={isChild}
                classes={classes}
                truncateStr={truncateStr}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  config: PropTypes.any
};
