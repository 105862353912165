import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const CustomTooltip = ({ title = '', placement = 'top-start', arrow = true, children }) => {
  return (
    <>
      {title ? (
        <Tooltip title={title} placement={placement} arrow={arrow}>
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default CustomTooltip;
