import {
  SETCOMPANYAPICONTEXT,
  SETCOMPANYMENUCLICKED,
  SETGRIDCONFIG,
  SETCOLCONFIG,
  SETFILTER,
  SETINPUTFILTER,
  SETPREVIOUSFILTER,
  SETUUID,
  SETUSERMESSAGE,
  SETRESPMESSAGE,
  SETCOMPANYDETAILS,
  SETHOMETABS,
  SETCOMPANYNAME,
  SETCOMPANYCIN,
  SETISCOMPANYDATAVISIBLE,
  SETLOADING,
  SETLOADINGANSWERSTREAM,
  SETLOADINGANSWERSTREAMDE,
  SETSHOWCOMPANYBUTTON,
  SETISCOMPANYDATAPOPULATED,
  SETALLMESSAGES,
  SETALLMESSAGESDE,
  SETCHATSCROLL,
  SETCHATSCROLLDE,
  SETCHATSEARCHINPUT,
  SETCHATSEARCHINPUTDE,
  SHOWCOMPANYPAGE,
  UPDATEPREVIOUSROUTE,
  SETASKBOTWIDTH,
  RESETSTATE,
  SETSTOPRESPONSE,
  SETSTOPRESPONSEDE,
  SETREGENERATERESPONSE,
  SETQUESTIONTABS,
  SETEXPLOREPAGINATION,
  SETNEWS,
  SETDASHBOARDNEWS,
  SETISFIRSTTIMEUSER,
  SETISSTEPFINISHED,
  SETWHITELABELDETAILS,
  SETSHOWTOURGUIDE,
  SETCURRENTSTEP,
  ENDTOURGUIDE,
  SETCOMPANYDASHBOARDDETAILS,
  SETTEARSHEETKEYS,
  SETTEARSHEETTEMPLATEOPTIONS,
  SETTEARSHEETDATA,
  SETTEARSHEETENTITIESOPTIONS,
  SETMCADATA,
  SETDASHBOARDQUESTION,
  SETCOMPANYLISTDETIALS,
  SETSELECTEDDOCEXP,
  SETLOADINGDE,
  SETDETAGS,
  SETSUGGESTEDDEQUESTIONS
} from '../../redux/action.types';
export const setGridConfig = (data) => {
  return {
    type: SETGRIDCONFIG,
    data
  };
};

export const setColConfig = (data) => {
  return {
    type: SETCOLCONFIG,
    data
  };
};

export const setFilter = (data) => {
  return {
    type: SETFILTER,
    data
  };
};

export const setInputFilter = (data) => {
  return {
    type: SETINPUTFILTER,
    data
  };
};

export const setPreviousFilter = (data) => {
  return {
    type: SETPREVIOUSFILTER,
    data
  };
};

export const setUuID = (data) => {
  return {
    type: SETUUID,
    data
  };
};

export const setUserMessage = (data) => {
  return {
    type: SETUSERMESSAGE,
    data
  };
};

export const setRespMessage = (data) => {
  return {
    type: SETRESPMESSAGE,
    data
  };
};

export const setCompanyDetails = (data) => {
  return {
    type: SETCOMPANYDETAILS,
    data
  };
};

export const setCompanyDashBoardDetails = (data) => {
  return {
    type: SETCOMPANYDASHBOARDDETAILS,
    data
  };
};

export const setHomeTabs = (data) => {
  return {
    type: SETHOMETABS,
    data
  };
};

export const setCompanyName = (data) => {
  return {
    type: SETCOMPANYNAME,
    data
  };
};

export const setCompanyCin = (data) => {
  return {
    type: SETCOMPANYCIN,
    data
  };
};

export const setCompanyApiContext = (data) => {
  return {
    type: SETCOMPANYAPICONTEXT,
    data
  };
};

export const setCompanyMenuClickedStatus = (data) => {
  return {
    type: SETCOMPANYMENUCLICKED,
    data
  };
};

export const setIsCompanyDataVisible = (data) => {
  return {
    type: SETISCOMPANYDATAVISIBLE,
    data
  };
};

export const setLoading = (data) => {
  return {
    type: SETLOADING,
    data
  };
};

export const setLoadingDE = (data) => {
  return {
    type: SETLOADINGDE,
    data
  };
};

export const setLoadingAnswerStream = (data) => {
  return {
    type: SETLOADINGANSWERSTREAM,
    data
  };
};

export const setLoadingAnswerStreamDE = (data) => {
  return {
    type: SETLOADINGANSWERSTREAMDE,
    data
  };
};

export const setShowCompanyButton = (data) => {
  return {
    type: SETSHOWCOMPANYBUTTON,
    data
  };
};

export const setIsCompanyDataPopulated = (data) => {
  return {
    type: SETISCOMPANYDATAPOPULATED,
    data
  };
};

export const setAllMessages = (data) => {
  return {
    type: SETALLMESSAGES,
    data
  };
};

export const setAllMessagesDE = (data) => {
  return {
    type: SETALLMESSAGESDE,
    data
  };
};

export const setChatScroll = (data) => {
  return {
    type: SETCHATSCROLL,
    data
  };
};

export const setChatScrollDE = (data) => {
  return {
    type: SETCHATSCROLLDE,
    data
  };
};

export const setChatSearchInput = (data) => {
  return {
    type: SETCHATSEARCHINPUT,
    data
  };
};

export const setChatSearchInputDE = (data) => {
  return {
    type: SETCHATSEARCHINPUTDE,
    data
  };
};

export const isShowCompanyPage = (data) => {
  return {
    type: SHOWCOMPANYPAGE,
    data
  };
};

export const updatePreviousRoute = (data) => {
  return {
    type: UPDATEPREVIOUSROUTE,
    data
  };
};

export const setAskBotWidth = (data) => {
  return {
    type: SETASKBOTWIDTH,
    data
  };
};

export const setStopResponse = (data) => {
  return {
    type: SETSTOPRESPONSE,
    data
  };
};

export const setStopResponseDE = (data) => {
  return {
    type: SETSTOPRESPONSEDE,
    data
  };
};

export const setRegenerateResponse = (data) => {
  return {
    type: SETREGENERATERESPONSE,
    data
  };
};

export const setResetState = () => {
  return {
    type: RESETSTATE
  };
};

export const setQuestionTabs = (data) => {
  return {
    type: SETQUESTIONTABS,
    data
  };
};

export const setExplorePagination = (data) => {
  return {
    type: SETEXPLOREPAGINATION,
    data
  };
};

export const setNews = (data) => {
  return {
    type: SETNEWS,
    data
  };
};

export const setDashBoardNews = (data) => {
  return {
    type: SETDASHBOARDNEWS,
    data
  };
};

export const setIsFirstTimeUser = (data) => {
  return {
    type: SETISFIRSTTIMEUSER,
    data
  };
};

export const setIsStepFinished = (data) => {
  return {
    type: SETISSTEPFINISHED,
    data
  };
};

export const setWhiteLabelDetails = (data) => {
  return {
    type: SETWHITELABELDETAILS,
    data
  };
};

export const setShowTourGuide = (data) => {
  return {
    type: SETSHOWTOURGUIDE,
    data
  };
};

export const setCurrentStepNumber = (data) => {
  return {
    type: SETCURRENTSTEP,
    data
  };
};

export const endTourGuide = () => {
  return {
    type: ENDTOURGUIDE
  };
};

export const setTearSheetKeys = (data) => {
  return {
    type: SETTEARSHEETKEYS,
    data
  };
};

export const setTearSheetEntitiesOptions = (data) => {
  return {
    type: SETTEARSHEETENTITIESOPTIONS,
    data
  };
};

export const setTearSheetTemplateOptions = (data) => {
  return {
    type: SETTEARSHEETTEMPLATEOPTIONS,
    data
  };
};

export const setTearSheetData = (data) => {
  return {
    type: SETTEARSHEETDATA,
    data
  };
};

export const setMcaData = (data) => {
  return {
    type: SETMCADATA,
    data
  };
};

export const setDashBoardQuestion = (data) => {
  return {
    type: SETDASHBOARDQUESTION,
    data
  };
};

export const setCompanyListDetials = (data) => {
  return {
    type: SETCOMPANYLISTDETIALS,
    data
  };
};

export const setSelectedDocExp = (data) => {
  return {
    type: SETSELECTEDDOCEXP,
    data
  };
};

export const setDeTags = (data) => {
  return {
    type: SETDETAGS,
    data
  };
};

export const setSuggestedDeQuestions = (data) => {
  return {
    type: SETSUGGESTEDDEQUESTIONS,
    data
  };
};
