import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    height: '80vh', // Adjust the height as needed
    width: '100%'
  }
});

const BoxContentExplorer = ({ folderId = '0', accessToken, handleShareBoxItems }) => {
  const classes = useStyles();
  const containerRef = useRef(null);

  useEffect(() => {
    if (window.Box) {
      const contentPicker = new window.Box.ContentPicker();
      contentPicker.show(folderId, accessToken, {
        container: containerRef.current,
        selectableTypes: ['file', 'folder'],
        canUpload: false,
        canCreateNewFolder: false,
        onChoose: (items) => {
          handleItemSelection(items);
        }
      });
    }
  }, [folderId, accessToken]);

  const handleItemSelection = async (items) => {
    const payload = createPayload(items);
    handleShareBoxItems(payload);
  };

  const createPayload = (items) => {
    return {
      items: items.map((item) => ({
        id: item.id,
        type: item.type
      }))
    };
  };

  return (
    <div
      ref={containerRef}
      className={classes.container} // Apply the styled container class
    ></div>
  );
};

export default BoxContentExplorer;
