import { makeStyles } from '@material-ui/core';
import { Chip, Tooltip } from '@mui/material';
import { ReactComponent as ExceptionIcon } from '../../icons/ExceptionsIcon.svg';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles({
  statusChip: {
    borderRadius: '5px !important',
    background: 'none !important',
    padding: '4px 2px !important',
    fontFamily: 'Satoshi !important',
    fontWeight: '500',
    fontSize: '12px !important'
  },
  processed: {
    border: '1px solid #DD950D!important',
    color: '#DD950D!important',
    background: '#FFF6E4!important'
  },
  ingested: {
    border: '1px solid #22A428!important',
    color: '#22A428!important',
    background: '#F3FFF4!important'
  },
  deleted: {
    border: '1px solid #f1b8b8!important',
    color: '#f1b8b8!important',
    background: '#fdf0f0!important'
  },
  failed: {
    border: '1px solid #c44545 !important',
    color: '#c44545!important',
    background: '#FFF9F9!important'
  },
  deletedFailed: {
    border: '1px solid #c44545!important',
    color: '#c44545!important',
    background: '#FFF9F9!important'
  },
  queue: {
    border: '1px solid #3473ee!important',
    color: '#3473ee!important',
    background: '#F4F8FF!important'
  },
  archive: {
    border: '1px solid #3473ee!important',
    color: '#3473ee!important',
    background: '#F4F8FF!important'
  },
  deleting: {
    border: '1px solid #5E5E5E !important',
    color: '#767676 !important',
    background: '#FAFAFA !important'
  },
  exceptionIcon: {
    width: '16px',
    height: '16px',
    right: '-12px',
    top: '-8px',
    position: 'absolute'
  }
});

export const statusOptions = [
  {
    key: 'QUEUED',
    value: 'QUEUED',
    styleClass: 'queue'
  },
  {
    key: 'PROCESSING',
    value: 'PROCESSING',
    styleClass: 'processed'
  },
  {
    key: 'INGESTED',
    value: 'INGESTED',
    styleClass: 'ingested'
  },
  {
    key: 'INGESTING',
    value: 'INGESTING',
    styleClass: 'processed'
  },
  {
    key: 'DELETE_IN_PROGRESS',
    value: 'DELETING',
    styleClass: 'deleting'
  },
  {
    key: 'DELETED',
    value: 'DELETED',
    styleClass: 'deleted'
  },
  {
    key: 'FAILED',
    value: 'FAILED',
    styleClass: 'failed'
  },
  {
    key: 'DELETED_FAILED',
    value: 'DELETE FAILED',
    styleClass: 'deletedFailed'
  },
  {
    key: 'ARCHIVED',
    value: 'ARCHIVED',
    styleClass: 'archive'
  }
];

const StatusChip = ({ status, exception }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const option = statusOptions.find((option) => option.key === status);
  return (
    <Chip
      label={option?.value}
      size="small"
      {...(exception
        ? {
            onDelete: () => exception(),
            deleteIcon: (
              <Tooltip title={t('statusChip.exceptionToolTipMessage')} placement="top">
                <ExceptionIcon className={classes.exceptionIcon} />
              </Tooltip>
            )
          }
        : {})}
      className={`${classes.statusChip} ${classes[option?.styleClass]}`}
    />
  );
};

export default StatusChip;
