export const updateParamsInURL = ({
  navigate,
  location,
  addParams = {},
  removeParams = [],
  keepParams = [],
  isNew = false
}) => {
  const params = new URLSearchParams(isNew ? {} : location.search);

  if (removeParams.length > 0) {
    removeParams.forEach((param) => {
      params.delete(param);
    });
  } else if (keepParams.length > 0) {
    const allParams = [...params.keys()];
    allParams.forEach((param) => {
      if (!keepParams.includes(param)) {
        params.delete(param);
      }
    });
  }

  for (const [key, value] of Object.entries(addParams)) {
    params.set(key, value);
  }

  navigate(`?${params.toString()}`);
};

export const removeObjRef = (data) => {
  if (data === null || typeof data === 'undefined') {
    return null;
  }
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (error) {
    return null;
  }
};

export const hideShowMessage = (msg, fn, tout = 5000) => {
  fn(msg);
  setTimeout(() => {
    fn('');
  }, tout);
};

export const arraysMatchExactly = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const getFirstProperty = (obj) => {
  if (!obj || typeof obj !== 'object' || Object.keys(obj).length === 0) {
    return '';
  }

  var firstKey = Object.keys(obj)[0];
  var firstValue = obj[firstKey];
  return firstValue;
};

export const printContent = (content = '', title = 'Private Blok', additionalStyles = '') => {
  const iframe = document.createElement('iframe');
  iframe.style.position = 'absolute';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.border = 'none';
  iframe.style.visibility = 'hidden';

  document.body.appendChild(iframe);

  const contentToPrint = content; //document.getElementById(contentId)?.innerHTML;
  if (!contentToPrint) {
    console.error(`Content not found`);
    document.body.removeChild(iframe);
    return;
  }

  const doc = iframe.contentDocument || iframe.contentWindow.document;
  doc.open();

  doc.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>
            @media print {
              .logo {
                padding-left: 20px;
                width: 200px;
              }
              ${additionalStyles}
            }
          </style>
        </head>
        <body>
          <div style="text-align: center; margin-bottom: 20px;">
            <img src="/images/PBLogo.png" alt="Logo" class="logo" />
          </div>
          ${contentToPrint}
        </body>
      </html>
    `);
  doc.close();

  iframe.onload = () => {
    iframe.contentWindow.print();
    document.body.removeChild(iframe);
  };
};
