import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getApi } from '../../services/token';
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import ErrorToast from '../atoms/errorToast';
import { useTranslation } from 'react-i18next';
import useFavicon from '../faviconHook';

const useStyles = makeStyles(() => ({
  iframe: {
    height: '100.5vh',
    width: '99.75vw',
    margin: '-7px -2px'
  }
}));

const url = process.env.REACT_APP_API;
const DocViewer = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const doc_type = searchParams.get('doc_type');
  const page_no = searchParams.get('page') || 1;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [docUrl, setDocUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useFavicon('/source_favicon.ico');

  useEffect(() => {
    const getDoc = async () => {
      setLoading(true);
      try {
        const res = await getApi(`${url}/v1/file/download`, {
          key: key,
          doc_type: doc_type
        });
        setDocUrl(`${res.data.data}#page=${page_no}`);
      } catch (err) {
        setErrorMessage(t('docViewer.apiError'));
        setTimeout(() => setErrorMessage(''), 10000);
        console.log(err);
      }
      setLoading(false);
    };
    getDoc();
  }, []);

  return (
    <>
      {loading && <LinearProgress />}
      <ErrorToast errorMessage={errorMessage} />
      <iframe src={`${docUrl}`} className={classes.iframe} allowFullScreen></iframe>
    </>
  );
};

export default DocViewer;
