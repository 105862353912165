import React from 'react';
import useChatStyles from '../styles';
import ChipComponent from '../../../../atoms/chipComponent';
import { Skeleton } from '@mui/material';

function FollowUpComponent({
  messageData = [],
  loading = false,
  handleCardQuestionClick = () => {},
  title = '',
  disabled = false
}) {
  const classes = useChatStyles();
  return (
    <>
      {loading ? (
        <>
          <div id="followUpMain">
            <div className={classes.headingText}>Suggested questions</div>
            {[...Array(3)].map((_, i) => (
              <div key={i} className={classes.skeletonMain}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 4 }}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="95%"
                  height="16px"
                  animation="wave"
                  className={classes.skeletonCh2}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          {messageData && messageData.length > 0 && (
            <div id="followUpMain" className="followUpMain">
              <div className={classes.headingText}>Suggested questions</div>
              {messageData.map((question, i) => {
                return (
                  <ChipComponent
                    title={title}
                    key={i}
                    label={question}
                    onClick={(e) => {
                      handleCardQuestionClick(e, question);
                    }}
                    className={`${classes.suggestedQues}`}
                    disabled={disabled}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FollowUpComponent;
