import React, { useState } from 'react';
import { Button, Typography, Menu } from '@mui/material';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import MenuContent from './fullWidthMenu';
// import { useNavigate } from 'react-router-dom';

const DynamicMenu = ({ menuConfig, classes, dynamicActiveMenu, setDynamicActiveMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={`${classes.buttonLink} ${classes.showOnDesktop} ${
          (dynamicActiveMenu || dynamicActiveMenu?.name) === menuConfig.name
            ? classes.gradientCls
            : ''
        }`}
        onClick={handleClick}
      >
        <Typography
          className={`${classes.anchorLink} link-button`}
          style={{
            fontFamily: 'Satoshi',
            fontWeight: 500,
            fontSize: '18px',
            color: '#171717'
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {menuConfig.name} <SeeMore style={{ padding: '0px 0px 0px 6px' }} />
          </span>
        </Typography>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          menuConfig.type === 'product-dropdown'
            ? { vertical: 'bottom', horizontal: 'center' }
            : { vertical: 'bottom', horizontal: 'right' }
        }
        transformOrigin={
          menuConfig.type === 'product-dropdown'
            ? { vertical: 'top', horizontal: 'center' }
            : { vertical: 'top', horizontal: 'right' }
        }
        sx={{
          '& .MuiPaper-root':
            menuConfig.type === 'product-dropdown'
              ? {
                  width: '90%',
                  borderRadius: '8px'
                }
              : {}
        }}
      >
        <MenuContent
          menuConfig={menuConfig}
          handleClose={handleClose}
          dynamicActiveMenu={dynamicActiveMenu}
          setDynamicActiveMenu={setDynamicActiveMenu}
        />
      </Menu>
    </>
  );
};

export default DynamicMenu;
