import React from 'react';
import { Grid, Typography, Divider, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dropdownHeading: {
    fontFamily: 'Satoshi !important',
    color: '#373737 !important',
    fontWeight: '700 !important',
    fontSize: '14px !important'
  },
  breakLine: {
    background: '#EDEDED',
    height: '1px'
  },
  dropdownTxt: {
    fontFamily: 'Satoshi !important',
    color: '#5E5E5E !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    lineHeight: '22px !important',
    paddingBottom: '14px'
  },
  demoBtn: {
    height: '35px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    padding: '0px 14px'
  },
  demoBtnTxt: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Satoshi',
    padding: '0px 16px'
  },
  dropdownBtn: {
    fontFamily: 'Satoshi !important',
    color: '#373737 !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    padding: '6px 0px',
    cursor: 'pointer',
    '&:hover': {
      color: '#004CFF !important',
      backgroundColor: 'transparent !important'
    }
  },
  dropdownBtnDisabledCls: {
    color: 'gray !important',
    opacity: '0.5',
    cursor: 'default',
    padding: '6px 0px'
  },
  dropdownBtngradientCls: {
    color: '#004CFF !important'
  },
  dropdownBtnDisabledtCls: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    padding: '6px 0px',
    color: 'gray !important',
    opacity: '0.5',
    cursor: 'default'
  }
}));

const MenuContent = ({ menuConfig, handleClose, dynamicActiveMenu, setDynamicActiveMenu }) => {
  const classes = useStyles();
  const { CustomSection, menuSections, menuItems } = menuConfig;

  if (menuConfig.type === 'product-dropdown' && Array.isArray(menuSections)) {
    const xsWidth = 12 / (CustomSection ? menuSections.length + 1 : menuSections.length);
    return (
      <div style={{ display: 'flex', padding: '0px 16px' }}>
        <Grid container>
          {CustomSection && (
            <CustomSection handleClose={handleClose} dropdownClasses={classes} xsWidth={xsWidth} />
          )}
          {menuSections.map((section, index) => (
            <Grid item xs={xsWidth} style={{ padding: '8px 16px' }} key={index}>
              <Typography className={classes.dropdownHeading}>{section.sectionName}</Typography>
              <Divider sx={{ margin: '10px 0' }} className={classes.breakLine} />
              {section.menuItems.map((item, idx) => (
                <Typography
                  key={idx}
                  onClick={() => {
                    if (!item.disabled) {
                      setDynamicActiveMenu(item);
                      item.onclick();
                      handleClose();
                    }
                  }}
                  className={
                    item.disabled
                      ? classes.dropdownBtnDisabledCls
                      : `${classes.dropdownBtn} ${
                          dynamicActiveMenu?.labelKey === item.labelKey
                            ? classes.dropdownBtngradientCls
                            : ''
                        }`
                  }
                >
                  {item.labelKey}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

  return menuItems.map((item, index) => (
    <MenuItem
      key={index}
      onClick={() => {
        item.onclick();
        handleClose();
        setDynamicActiveMenu(item);
      }}
      style={{
        fontFamily: 'Satoshi',
        fontWeight: 500,
        fontSize: '16px',
        color: '#171717'
      }}
      className={`${classes.dropdownBtn} ${
        dynamicActiveMenu?.labelKey === item.labelKey ? classes.dropdownBtngradientCls : ''
      }`}
    >
      {item.labelKey}
    </MenuItem>
  ));
};

export default MenuContent;
