import React, { useState, useEffect, useRef } from 'react';
import { Grid, IconButton, Button, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { getApi, postApi } from '../../../services/token';
import { Card, CardActionArea, CardContent } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@mui/icons-material/Business';
import SocialMedia from '../Accordians/socialMedia';
import NewsFeed from '../askBot/news';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, Divider } from '@material-ui/core';
import MCAIconComp from '../askBot/MCAIconComp';
import HeartIcon from '../askBot/HeartIcon';
import { Tooltip, Chip } from '@mui/material';
import { setHomeTabs, setMcaData, setDashBoardQuestion, setDashBoardNews } from '../action';
import { ReactComponent as DashBoarNewsIcon } from '../../../components/icons/DashBoarNewsIcon.svg';
import { ReactComponent as DashBoardKeyMetricsIcon } from '../../../components/icons/DashBoardKeyMetricsIcon.svg';
import { ReactComponent as DashBoardFundingIcon } from '../../../components/icons/DashBoardFundingIcon.svg';
import { ReactComponent as EmailIcon } from '../../../components/icons/EmailIcon.svg';
import { ReactComponent as LocationIcon } from '../../../components/icons/LocationIcon.svg';
import { ReactComponent as WebSiteIcon } from '../../../components/icons/WebSiteIcon.svg';
import { ReactComponent as DashBoardAskByzIcon } from '../../../components/icons/DashBoardAskByzIcon.svg';
import { ReactComponent as DashBoardCompetitorsIcon } from '../../../components/icons/DashBoardCompetitorsIcon.svg';
import { ReactComponent as DashBoardMyDocIcon } from '../../../components/icons/DashBoardMyDocIcon.svg';
import { ReactComponent as DashBoardTearSheetIcon } from '../../../components/icons/DashBoardTearSheetIcon.svg';
import { ReactComponent as LeftArrowHiddenIcon } from '../../../components/icons/LeftArrowHiddenIcon.svg';
import { ReactComponent as LeftArrowIcon } from '../../../components/icons/LeftArrowIcon.svg';
import { ReactComponent as RightArrowHiddenIcon } from '../../../components/icons/RightArrowHiddenIcon.svg';
import { ReactComponent as RightArrowIcon } from '../../../components/icons/RightArrowIcon.svg';
import { ReactComponent as NoFilesIcon } from '../../../components/icons/NoFilesIcon.svg';
import { ReactComponent as AddIconPlus } from '../../../components/icons/AddIconPlus.svg';
import DashBoardHeadder from './dashBoardHeadder';
import ErrorToast from '../../atoms/errorToast';

const useStyles = makeStyles((theme) => ({
  active: {
    color: 'white!important',
    background: 'linear-gradient(90deg, rgb(0, 76, 255), rgb(6, 188, 193))'
  },
  mainTabBtn: {
    'text-transform': 'none!important',
    'margin-top': '6px',
    padding: '6px 26px !important',
    'border-radius': '8px',
    '& img': {
      'margin-right': '10px',
      top: '1px',
      position: 'relative'
    },
    borderRadius: '8px!important'
  },
  btnColor: {
    color: '#767676!important',
    borderRadius: '8px!important'
  },
  tabClsQuestions: {
    background: '#ffffff',
    display: 'flex',
    border: '1px #E7E7E7 solid',
    'border-radius': '12px',
    '& div': {
      padding: '2px'
    }
  },
  tabClsBtn: {
    'text-transform': 'none!important',
    'margin-top': '6px',
    color: '#767676',
    'border-radius': '8px'
  },
  stepClass: {
    '&::before': {
      border: '10px solid transparent',
      content: '""',
      position: 'absolute',
      top: '27px',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent #ffffff transparent',
      transform: 'translateX(-50%)',
      zIndex: 9999
    }
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: theme.spacing(2)
  },
  typographyValue: {
    fontSize: '14px !important',
    fontFamily: 'Satoshi !important',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#373737'
  },
  typographyDetailsKey: {
    fontSize: '14px !important',
    fontFamily: 'Satoshi !important',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#373737'
  },
  typographyKey: {
    fontSize: '18px !important',
    fontFamily: 'Satoshi !important',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#171717'
  },
  typographyLinkKey: {
    fontSize: '18px !important',
    fontFamily: 'Satoshi !important',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#171717',
    cursor: 'pointer',
    '&:hover': {
      color: '#004CFF'
    }
  },
  displayInline: {
    display: 'flex',
    justifyContent: 'center',
    'padding-top': '2px!important'
  },
  iconsMainDiv: {
    [theme.breakpoints.down('sm')]: {
      'margin-right': '3%'
    },
    [theme.breakpoints.up('sm')]: {
      'margin-right': '4%'
    }
  },
  iconParent: {
    display: 'inline-block',
    'font-size': '18px',
    'line-height': '30px',
    width: '30px',
    height: '30px',
    'border-radius': '36px',
    'background-color': '#EFF3FC',
    color: '#fff',
    margin: '0 3px 3px 0'
  },
  socialMediaIcon: {
    [theme.breakpoints.up('sm')]: {
      padding: '7px',
      width: '50%',
      height: '45%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: '45%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '45%'
    }
  },
  cardHeadding: {
    display: 'flex',
    alignItems: 'center'
  },
  noScrollbar: {
    overflowY: 'hidden', // Hide scrollbar by default
    overflowX: 'hidden', // Hide scrollbar by default
    scrollbarGutter: 'stable both-edges',
    '&:hover': {
      overflowY: 'auto', // Show scrollbar on hover
      overflowX: 'auto' // Show scrollbar on hover
    }
  },
  newCard: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #F0F0F0',
    borderRadius: '10px',
    boxShadow: '0px 2px 4px 0px #0000000D',
    backgroundColor: '#FAFAFA !important'
  },
  newsCardContent: {
    flexGrow: '8!important',
    width: '70% !important',
    padding: '7px !important'
  },
  newsDateTime: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    fontSize: '12px !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  newsTitle: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: '#373737 !important'
  },
  newsSource: {
    fontFamily: 'Satoshi !important',
    fontSize: '12px !important',
    color: '#373737 !important',
    fontWeight: '700 !important'
  },
  newsCardMedia: {
    flexGrow: '2 !important',
    width: '30% !important',
    borderRadius: '7px !important',
    height: '60px !important',
    objectFit: 'cover !important',
    padding: '2px !important'
  },
  newsSeeMore: {
    fontFamily: 'Satoshi !important',
    fontWeight: 'bold !important',
    fontSize: '12px !important'
  },
  selected: {
    padding: '6px 8px !important',
    borderRadius: '4px !important',
    border: '1px solid #E4EBFC !important',
    backgroundColor: '#E5EDFF !important',
    textTransform: 'none !important'
  },
  unSelected: {
    padding: '6px 8px !important',
    borderRadius: '4px !important',
    border: '1px solid #FFFFFF !important',
    backgroundColor: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  selectedText: {
    fontFamily: 'Satoshi !important',
    color: '#004CFF',
    fontSize: '14px',
    fontWeight: '700 !important'
  },
  unSelectedText: {
    fontFamily: 'Satoshi !important',
    color: '#171717',
    fontSize: '14px',
    fontWeight: '700 !important'
  },
  listStyles: {
    padding: '10px 0px'
  },
  personName: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '700 !important',
    lineHeight: '22px !important'
  },
  personRole: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '400 !important',
    lineHeight: '22px !important'
  },
  keyMetricsData: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '400 !important',
    lineHeight: '24px !important'
  },
  divider: {
    backgroundColor: '#F0F0F0',
    margin: '2px 0px'
  },
  investorsName: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '400 !important',
    lineHeight: '32px !important'
  },
  tableCellHead: {
    border: '1px solid #E8E8E8',
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    lineHeight: '20px !important',
    padding: '6px 4px !important',
    color: '#373737 !important',
    background: '#FAFAFA !important',
    verticalAlign: 'top !important'
  },
  tableCellRow: {
    border: '1px solid #E8E8E8',
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '20px !important',
    padding: '6px 4px !important',
    color: '#373737 !important',
    verticalAlign: 'top !important'
  },
  tableContainer: {
    padding: '0px !important'
  },
  tableHead: {
    border: '1px solid #E8E8E8',
    background: '#EDEDED',
    height: '34px'
  },
  mostQuestoinAsked: {
    fontFamily: 'Satoshi !important',
    color: '#0680E7',
    fontSize: '16px',
    fontWeight: '500 !important',
    lineHeight: '24px',
    cursor: 'pointer',
    padding: '6px 6px 6px '
  },
  templateDataUsed: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '22px !important'
  },
  uploadButtonText: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '22px !important',
    cursor: 'pointer',
    paddingLeft: '10px'
  },
  templateName: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: '#373737',
    lineHeight: '22px !important'
  },
  docFileName: {
    fontFamily: 'Satoshi !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: '#373737',
    lineHeight: '22px !important',
    cursor: 'pointer',
    '&:hover': {
      color: '#004CFF !important'
    }
  },
  uploadButtonTextDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  entityStyle: {
    fontFamily: 'Satoshi !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: '#000000',
    lineHeight: '22px !important'
  },
  entityHeadingStyle: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    color: '#000000',
    lineHeight: '22px !important',
    paddingTop: '6px'
  },
  entityBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid #B3CDFA',
    background: '#EAF1FE',
    padding: '5px 8px',
    minHeight: '20px'
  },
  financialperson: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '700 !important',
    lineHeight: '16px !important'
  },
  financialpersonDate: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '400 !important',
    lineHeight: '16px !important'
  },
  yearTab: {
    padding: '6px 2px !important',
    borderRadius: '4px !important',
    border: '1px solid #EAF1FE !important',
    backgroundColor: '#E4EBFC !important',
    textTransform: 'none !important',
    margin: '3px 1px !important'
  },
  yearTabTxt: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '500 !important',
    padding: '0px'
  },
  reportBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  addReport: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    width: '100%'
  }
}));

const DashBoard = () => {
  const classes = useStyles();

  const companyUrl = process.env.REACT_APP_COMPANY_API;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.tokenReducer.companyDashBoardDetails);
  const companyDashBoardDetails = useSelector(
    (state) => state.tokenReducer.companyDashBoardDetails
  );

  function shallowEqual(objA, objB) {
    for (let key in objB) {
      if (!(key in objA)) {
        return false;
      }
    }
    return true;
  }

  const dashBoardNews = useSelector((state) => state.tokenReducer.dashBoardNews, shallowEqual);
  const currentActiveCompany =
    companyDetails && companyDetails.length > 0
      ? companyDetails.find((x) => x.active === true) || null
      : null;

  // const companyDetailsData = currentActiveCompany?.data?.tabs[0].Overview?.Details?.bt_value;

  const overviewTab = currentActiveCompany?.data?.tabs.find((tab) => tab.Overview);
  const companyDetailsData = overviewTab?.Overview?.Details?.bt_value;

  const isSocialMediaEmpty = (socialMediaData) => {
    const keys = Object.keys(socialMediaData);
    const allowedKeys = ['Website', 'Crunchbase Url'];
    return keys.every((key) => allowedKeys.includes(key));
  };

  const socialMediaData = overviewTab?.Overview?.['Social Media']?.bt_value;

  const shouldRenderSocialMedia = socialMediaData && !isSocialMediaEmpty(socialMediaData);

  const people = currentActiveCompany?.data?.tabs.find((tab) => tab.People)?.People;
  const investors = currentActiveCompany?.data?.tabs.find((tab) => tab.Investors)?.Investors;

  const financial = currentActiveCompany?.data?.key_metrics;
  const companyQuestions = currentActiveCompany?.companyQuestions;
  const competitors = currentActiveCompany?.data?.competitors;
  const investments = currentActiveCompany?.data?.investments?.bt_value;
  // const peopleTabs = Object.keys(people);
  const [selectedPeopleTab, setSelectedPeopleTab] = useState('Key People');

  // Determine available sections for Investors
  const [selectedInvestorsTabsTab, setSelectedInvestorsTabsTab] = useState(
    'Key Institutional Investors'
  );

  const [selectedCompanyDetailTab, setSelectedCompanyDetailTab] = useState('Description');

  const handlePeopleTabClick = (section) => {
    setSelectedPeopleTab(section);
  };

  const handleInvestorsTabsClick = (section) => {
    setSelectedInvestorsTabsTab(section);
  };

  const handleCompanyDetailClick = (section) => {
    setSelectedCompanyDetailTab(section);
  };

  const renderKeyPeople = () => {
    return people?.['Key People']?.bt_value ? (
      <div className={`${classes.noScrollbar} ${classes.listStyles}`}>
        {people['Key People']?.bt_value.map((person, index) => (
          <div key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.personName}>{person.Name}</Typography>
              {'Linkedin' in person || 'LinkedIn' in person ? (
                <span>
                  <Link target="_blank" href={person['Linkedin'] || person['LinkedIn']}>
                    <img
                      src="/images/icons/Linkedin - Icon.svg"
                      alt=""
                      style={{ height: '12px', marginLeft: '5px' }}
                    />
                  </Link>
                </span>
              ) : null}
            </div>
            {'Role' in person ? (
              <Typography className={classes.personRole}>{person.Role}</Typography>
            ) : null}
            <Divider className={classes.divider} />
          </div>
        ))}
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '272px'
        }}
      >
        <Typography className={classes.investorsName}>Data Not Available</Typography>
      </div>
    );
  };

  const renderBoardMembers = () => {
    return people?.['Board Members']?.bt_value ? (
      <div className={`${classes.noScrollbar} ${classes.listStyles}`}>
        <TableContainer elevation={0} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellHead}>Name</TableCell>
                <TableCell className={classes.tableCellHead}>Start Date</TableCell>
                <TableCell className={classes.tableCellHead}>End Date</TableCell>
                <TableCell className={classes.tableCellHead}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {people['Board Members']?.bt_value.map((person, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellRow}>{person['Name']}</TableCell>
                  <TableCell className={classes.tableCellRow}>
                    {dateFormat(person['Start Date'])}
                  </TableCell>
                  <TableCell className={classes.tableCellRow}>{person['End Date']}</TableCell>
                  <TableCell className={classes.tableCellRow}>{person['Status']}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '272px'
        }}
      >
        <Typography className={classes.investorsName}>Data Not Available</Typography>
      </div>
    );
  };

  const renderFundingMembers = () => {
    return (
      <div className={`${classes.noScrollbar} ${classes.listStyles}`}>
        <TableContainer elevation={0}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCellHead}>Transaction Name</TableCell>
                <TableCell className={classes.tableCellHead}>Announcement Date</TableCell>
                <TableCell className={classes.tableCellHead}>Investors</TableCell>
                <TableCell className={classes.tableCellHead}>Investor Type</TableCell>
                <TableCell className={classes.tableCellHead}>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {investments?.map((investment, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellRow}>
                    {investment['Transaction Name']}
                  </TableCell>
                  <TableCell className={classes.tableCellRow}>
                    {dateFormat(investment['Announcement Date'])}
                  </TableCell>
                  <TableCell className={classes.tableCellRow}>
                    {investment['Lead Investor']}
                  </TableCell>
                  <TableCell className={classes.tableCellRow}>
                    {investment['Investment Type']}
                  </TableCell>
                  <TableCell className={classes.tableCellRow}>{investment['amount']}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderAngelInvestors = () => {
    return investors?.['Key Angel Investors']?.bt_value ? (
      <div className={`${classes.noScrollbar} ${classes.listStyles}`}>
        {investors['Key Angel Investors']?.bt_value.map((person, index) => (
          <div key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.investorsName}>{person}</Typography>
            </div>
            <Divider className={classes.divider} />
          </div>
        ))}
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '272px'
        }}
      >
        <Typography className={classes.investorsName}>Data Not Available</Typography>
      </div>
    );
  };

  const renderInstitutionalInvestors = () => {
    return investors?.['Key Institutional Investors']?.bt_value ? (
      <div className={`${classes.noScrollbar} ${classes.listStyles}`}>
        {investors['Key Institutional Investors']?.bt_value.map((person, index) => (
          <div key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.investorsName}>{person}</Typography>
            </div>
            <Divider className={classes.divider} />
          </div>
        ))}
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '272px'
        }}
      >
        <Typography className={classes.investorsName}>Data Not Available</Typography>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <>
        <div
          style={{
            height: shouldRenderSocialMedia ? '202px' : '240px',
            padding: '6px 0px'
          }}
          className={classes.noScrollbar}
        >
          <Typography className={classes.typographyValue}>
            {currentActiveCompany?.data?.tabs[0]?.Overview?.Description?.bt_value}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {shouldRenderSocialMedia && (
            <SocialMedia socialIconsData={socialMediaData} classes={classes} />
          )}
        </div>
      </>
    );
  };

  const renderDetails = () => {
    return (
      <div
        style={{
          height: '240px',
          padding: '6px 0px'
        }}
        className={classes.noScrollbar}
      >
        {companyDetailsData['Year Incorporated'] && (
          <Typography className={classes.typographyDetailsKey}>
            Year Incorporated :{' '}
            <span className={classes.typographyValue}>
              {companyDetailsData['Year Incorporated']}
            </span>
          </Typography>
        )}
        {companyDetailsData['Country Of Incorporation'] && (
          <Typography className={classes.typographyDetailsKey}>
            Country :{' '}
            <span className={classes.typographyValue}>
              {companyDetailsData['Country Of Incorporation']}
            </span>
          </Typography>
        )}
        {companyDetailsData['Total Employees'] && (
          <Typography className={classes.typographyDetailsKey}>
            Total Employees :{' '}
            <span className={classes.typographyValue}>{companyDetailsData['Total Employees']}</span>
          </Typography>
        )}
        {companyDetailsData['Website'] && (
          <Typography className={classes.typographyDetailsKey}>
            <IconButton style={{ paddingLeft: '0px' }}>
              <WebSiteIcon />
            </IconButton>{' '}
            <span className={classes.typographyValue}>
              <a href={companyDetailsData['Website']} target="_blank" rel="noopener noreferrer">
                {companyDetailsData['Website']}
              </a>
            </span>
          </Typography>
        )}
        {companyDetailsData['Email'] && (
          <Typography className={classes.typographyDetailsKey}>
            <IconButton style={{ paddingLeft: '0px' }}>
              <EmailIcon />
            </IconButton>{' '}
            <span className={classes.typographyValue}>
              <a href={`mailto:${companyDetailsData['Email']}`}>{companyDetailsData['Email']}</a>
            </span>
          </Typography>
        )}
        {companyDetailsData['Location'] && (
          <Typography className={classes.typographyDetailsKey}>
            <div style={{ display: 'flex' }}>
              <div>
                <IconButton style={{ paddingLeft: '0px' }}>
                  <LocationIcon />
                </IconButton>
              </div>
              <div>
                <span className={classes.typographyValue}>{companyDetailsData['Location']}</span>
              </div>
            </div>
          </Typography>
        )}
      </div>
    );
  };

  const [dashBoardTemplateList, setDashBoardTemplateList] = useState([]);
  const [companyDocList, setCompanyDocList] = useState([]);

  const getNews = async () => {
    const cin = currentActiveCompany?.data?.base?.CIN;
    if (dashBoardNews[cin] && dashBoardNews[cin].pageNumber) {
      return;
    }
    try {
      let res = await getApi(`${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}/news`, {
        page_size: 10,
        page_number: 1
      });
      let updatedItems = [];
      let updatedNews = JSON.parse(JSON.stringify(dashBoardNews));
      if (updatedNews[cin]) {
        updatedItems = JSON.parse(JSON.stringify(updatedNews[cin].items));
      } else {
        updatedNews[cin] = {};
      }
      if (res.data.data.items !== null) {
        updatedItems = [...updatedItems, ...res.data.data.items];
        updatedNews[cin].items = updatedItems;
        updatedNews[cin].pageNumber = res.data.data.metadata.total_records <= 10 ? -1 : 2;
        dispatch(setDashBoardNews({ ...updatedNews }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDashBoardTemplateList = async () => {
    try {
      const res = await getApi(
        `${companyUrl}/v2/report?entity_type=${'company'}&entity_id=${
          currentActiveCompany.data.base.CIN
        }`
      );
      let data = res.data.data;
      setDashBoardTemplateList(data);
    } catch (err) {
      console.log(err);
    }
  };
  const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
  const getCompanyDocList = async () => {
    try {
      let queryStr = `page_size=${10}&page_number=${1}&cin_list=${
        currentActiveCompany?.data?.base?.CIN
      }`;
      const ingestionData = await getApi(`${ingestionUrl}/v1/document?${queryStr}`);
      const { data } = ingestionData?.data || {};
      let { items = [], metadata = {} } = data || {};
      setCompanyDocList(items);
      console.log(metadata);
    } catch (err) {
      console.log(err);
    }
  };

  const allMcaData = useSelector((state) => state.tokenReducer.mcaData);

  const fetchMcaInfo = async () => {
    try {
      const cin = currentActiveCompany?.data?.base?.CIN;
      const mcaInfoData = await getApi(
        `${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}/mca/metrics`
      );
      let updatedMcaData = { ...allMcaData };
      updatedMcaData[cin] = mcaInfoData.data.data;
      dispatch(setMcaData(updatedMcaData));
    } catch (error) {
      console.error('Error fetching MCA info:', error);
    }
  };

  useEffect(() => {
    if (currentActiveCompany !== null) {
      getDashBoardTemplateList();
      getCompanyDocList();
      fetchMcaInfo();
      getNews();
    }
  }, [currentActiveCompany]);

  const containerRef = useRef(null);
  const containerRefDoc = useRef(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const [canScrollLeftDoc, setCanScrollLeftDoc] = useState(false);
  const [canScrollRightDoc, setCanScrollRightDoc] = useState(false);
  const [mcaErrorMessage, setMcaErrorMessage] = useState('');

  const handleScroll = (direction) => {
    const container = containerRef.current;
    const scrollAmount = 200; // Adjust scroll amount as needed

    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
    updateScrollButtons();
  };

  const updateScrollButtons = () => {
    const container = containerRef.current;
    setCanScrollLeft(container.scrollLeft > 0);
    setCanScrollRight(container.scrollLeft < container.scrollWidth - container.clientWidth);
  };

  const handleScrollDoc = (direction) => {
    const container = containerRefDoc.current;
    const scrollAmount = 200; // Adjust scroll amount as needed

    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
    updateScrollButtonsDoc();
  };

  const updateScrollButtonsDoc = () => {
    const container = containerRefDoc.current;
    setCanScrollLeftDoc(container.scrollLeft > 0);
    setCanScrollRightDoc(container.scrollLeft < container.scrollWidth - container.clientWidth);
  };

  const NoFilesUploadedTableComp = () => {
    const navigate = useNavigate();
    return (
      <div
        style={{
          display: 'flex',
          height: '106px',
          flexDirection: 'column',
          padding: '0px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <NoFilesIcon style={{ alignSelf: 'center' }} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            style={{
              fontFamily: 'Satoshi',
              fontSize: '14px',
              color: '#171717',
              fontWeight: 500,
              paddingTop: '20px'
            }}
          >
            No files uploaded yet for <strong>{currentActiveCompany?.data?.base?.Name}</strong>.
            <span
              style={{
                color: '#004CFF',
                cursor: 'pointer',
                fontWeight: 'bold',
                paddingLeft: '4px'
              }}
              onClick={() => {
                navigate(`/document-upload/upload`);
              }}
            >
              Upload Now
            </span>
          </Typography>
        </div>
      </div>
    );
  };

  const handleQuestionClickDashbaord = (event, question) => {
    navigate('/askbyz');
    dispatch(setHomeTabs(t('tab.AskBot')));
    dispatch(setDashBoardQuestion(question));
  };

  const dateFormat = (input) => {
    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let dateObj;

      if (input.includes(' ')) {
        // Handle timestamp format: "2023-11-16 00:00:00"
        const [datePart, timePart] = input.split(' ');
        const [year, month, day] = datePart.split('-');
        const [hour, minute] = timePart.split(':');
        dateObj = new Date(Date.UTC(year, month - 1, day, hour, minute));
      } else {
        // Handle dateString format: "04-10-2014"
        const [day, month, year] = input.split('-');
        dateObj = new Date(Date.UTC(year, month - 1, day));
      }

      const options = {
        year: 'numeric',
        month: 'short', // Use 'short' for abbreviated month names, or 'long' for full month names
        day: 'numeric',
        timeZone: userTimeZone
      };

      return dateObj.toLocaleString('en-IN', options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return '-';
    }
  };

  const requestMcaInfo = async (cin) => {
    try {
      await postApi(`${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}/mca/request`);
      await fetchMcaInfo(cin);
    } catch (error) {
      const errorStatus = error?.response?.status;
      if (errorStatus === 403 || errorStatus === 402) {
        setMcaErrorMessage(t('mca.requestLimitError'));
      } else {
        setMcaErrorMessage(t('mca.requestFailedMessage'));
      }
      setTimeout(() => setMcaErrorMessage(''), 10000);
      console.error('Error requesting MCA info:', error);
    }
  };

  // const validFinancialYears = financial?.financial_year
  //   ? financial.financial_year.filter(({ statement_link }) => statement_link !== null)
  //   : [];

  const validFinancialYears = financial?.financial_year ?? [];

  return (
    <>
      <ErrorToast errorMessage={mcaErrorMessage} />
      <div style={{ minHeight: 'calc(100vh - 120px)' }}>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <Grid>
            <DashBoardHeadder />
          </Grid>
        </div>
        {currentActiveCompany !== null && companyDashBoardDetails.length > 0 && (
          <Grid style={{ background: '#F7F9FD' }}>
            <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
              <div
                style={{
                  display: 'flex',
                  padding: '16px 20px 0px 20px'
                }}
              >
                {currentActiveCompany?.data?.base?.Logo ? (
                  <img
                    id="companyMainDivchHdCompChChImg"
                    src={currentActiveCompany?.data?.base?.Logo}
                    alt="Logo"
                    style={{
                      maxWidth: '60px',
                      maxHeight: '60px',
                      objectFit: 'contain',
                      padding: '0px 5px'
                    }}
                  />
                ) : (
                  <BusinessIcon
                    id="companyMainDivchHdCompChChBusIco"
                    style={{
                      width: '55px',
                      height: '55px'
                    }}
                  />
                )}
                <div
                  id="companyMainDivchHdCompChChCh"
                  style={{
                    fontFamily: 'Satoshi',
                    paddingLeft: '5px',
                    paddingRight: '10px',
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <p
                    id="companyMainDivchHdCompChChChp"
                    style={{
                      fontFamily: 'Satoshi',
                      fontWeight: '600',
                      marginRight: '22px',
                      marginLeft: '10px',
                      margin: '0px',
                      fontSize: '20px'
                    }}
                    className={classes.companyName}
                  >
                    {currentActiveCompany?.data?.base?.Name}
                  </p>
                </div>
                <MCAIconComp
                  requestMcaInfo={requestMcaInfo}
                  cin={currentActiveCompany?.data?.base?.CIN}
                />
                <HeartIcon company={currentActiveCompany} isDashBoard={true} />
                <div
                  style={{
                    paddingLeft: '10px',
                    maxHeight: '96px',
                    alignContent: 'center'
                  }}
                  className={classes.noScrollbar}
                >
                  {currentActiveCompany?.data?.base?.entity_relation?.theme?.length > 0 && (
                    <div style={{ display: 'flex' }}>
                      <Typography className={classes.entityHeadingStyle}>Theme:</Typography>
                      <Typography style={{ paddingLeft: '10px' }} className={classes.entityStyle}>
                        {currentActiveCompany?.data?.base?.entity_relation?.theme.map(
                          (theme, index) => (
                            <Chip
                              key={index}
                              label={theme.entity_name}
                              style={{
                                borderRadius: '4px',
                                margin: '2px 3px',
                                color: '#000000',
                                backgroundColor: '#EAF1FE',
                                border: '1px solid #B3CDFA'
                              }}
                            />
                          )
                        )}
                      </Typography>
                    </div>
                  )}
                  {currentActiveCompany?.data?.base?.entity_relation?.sub_theme?.length > 0 && (
                    <div style={{ display: 'flex' }}>
                      <Typography className={classes.entityHeadingStyle}>Subtheme:</Typography>
                      <Typography className={classes.entityStyle}>
                        {currentActiveCompany?.data?.base?.entity_relation?.sub_theme.map(
                          (focusArea, index) => (
                            <Chip
                              key={index}
                              label={focusArea.entity_name}
                              style={{
                                borderRadius: '4px',
                                margin: '2px 3px',
                                color: '#000000',
                                backgroundColor: '#EAF1FE',
                                border: '1px solid #B3CDFA'
                              }}
                            />
                          )
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <Grid container>
                <Grid xs={9}>
                  <Grid container>
                    <Grid style={{ padding: '10px' }} xs={4}>
                      <div
                        style={{
                          border: '1px solid #E7E7E7',
                          borderRadius: '8px',
                          background: '#FFFFFF',
                          height: '272px',
                          padding: '10px 14px',
                          display: 'flex'
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            paddingTop: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <Box>
                            <Button
                              className={
                                selectedCompanyDetailTab === 'Description'
                                  ? classes.selected
                                  : classes.unSelected
                              }
                              onClick={() => handleCompanyDetailClick('Description')}
                            >
                              <Typography
                                className={
                                  selectedCompanyDetailTab === 'Description'
                                    ? classes.selectedText
                                    : classes.unSelectedText
                                }
                              >
                                {' '}
                                Description{' '}
                              </Typography>
                            </Button>
                            <Button
                              className={
                                selectedCompanyDetailTab === 'Details'
                                  ? classes.selected
                                  : classes.unSelected
                              }
                              onClick={() => handleCompanyDetailClick('Details')}
                            >
                              <Typography
                                className={
                                  selectedCompanyDetailTab === 'Details'
                                    ? classes.selectedText
                                    : classes.unSelectedText
                                }
                              >
                                {' '}
                                Details{' '}
                              </Typography>
                            </Button>
                          </Box>
                          {selectedCompanyDetailTab === 'Description' && renderDescription()}
                          {selectedCompanyDetailTab === 'Details' && renderDetails()}
                        </Box>
                      </div>
                    </Grid>
                    <Grid style={{ padding: '10px' }} xs={4}>
                      <div
                        style={{
                          border: '1px solid #E7E7E7',
                          borderRadius: '8px',
                          background: '#FFFFFF',
                          height: '272px',
                          padding: '10px 14px',
                          display: 'flex'
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            paddingTop: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <Box>
                            <Button
                              className={
                                selectedPeopleTab === 'Key People'
                                  ? classes.selected
                                  : classes.unSelected
                              }
                              onClick={() => handlePeopleTabClick('Key People')}
                            >
                              <Typography
                                className={
                                  selectedPeopleTab === 'Key People'
                                    ? classes.selectedText
                                    : classes.unSelectedText
                                }
                              >
                                {' '}
                                Key People{' '}
                              </Typography>
                            </Button>
                            <Button
                              className={
                                selectedPeopleTab === 'Board Members'
                                  ? classes.selected
                                  : classes.unSelected
                              }
                              onClick={() => handlePeopleTabClick('Board Members')}
                            >
                              <Typography
                                className={
                                  selectedPeopleTab === 'Board Members'
                                    ? classes.selectedText
                                    : classes.unSelectedText
                                }
                              >
                                {' '}
                                Board Members{' '}
                              </Typography>
                            </Button>
                          </Box>
                          {selectedPeopleTab === 'Key People' && renderKeyPeople()}
                          {selectedPeopleTab === 'Board Members' && renderBoardMembers()}
                        </Box>
                      </div>
                    </Grid>
                    <Grid style={{ padding: '10px' }} xs={4}>
                      <div
                        style={{
                          border: '1px solid #E7E7E7',
                          borderRadius: '8px',
                          background: '#FFFFFF',
                          height: '272px',
                          padding: '10px 14px'
                        }}
                      >
                        <Box>
                          <div className={classes.cardHeadding}>
                            <IconButton style={{ paddingLeft: '5px' }}>
                              <DashBoardKeyMetricsIcon />
                            </IconButton>
                            <Typography className={classes.typographyKey}>Key Metrics</Typography>
                          </div>
                        </Box>
                        {/* <div style={{ display: 'flex', height: '230px' }}> */}
                        <div className={classes.noScrollbar} style={{ height: '230px' }}>
                          {financial ? (
                            <>
                              {financial?.company_stage && (
                                <div style={{ paddingTop: '10px' }}>
                                  <Typography className={classes.financialperson}>
                                    Company Stage
                                  </Typography>
                                  <div style={{ display: 'flex' }}>
                                    <Typography className={classes.keyMetricsData}>
                                      {financial?.company_stage}
                                    </Typography>
                                  </div>
                                  <Divider className={classes.divider} />
                                </div>
                              )}
                              {financial?.revenue?.annual_revenue && (
                                <div style={{ paddingTop: '4px' }}>
                                  <Typography className={classes.financialperson}>
                                    Annual Revenue
                                  </Typography>

                                  <div style={{ display: 'flex' }}>
                                    <Typography className={classes.keyMetricsData}>
                                      {financial.revenue.annual_revenue}
                                      {financial?.revenue?.financial_year && (
                                        <> as of FY{financial.revenue.financial_year}</>
                                      )}
                                    </Typography>
                                  </div>
                                  <Divider className={classes.divider} />
                                </div>
                              )}
                              {financial?.investments?.transaction_name && (
                                <div style={{ paddingTop: '4px' }}>
                                  <Typography className={classes.financialperson}>
                                    Last Funding Round
                                  </Typography>
                                  <div style={{ display: 'flex' }}>
                                    <Typography className={classes.keyMetricsData}>
                                      {financial.investments.transaction_name} as of{' '}
                                      {dateFormat(financial.investments.announcement_date)}
                                    </Typography>
                                  </div>
                                  <Divider className={classes.divider} />
                                </div>
                              )}
                              <div style={{ paddingTop: '10px' }}>
                                {validFinancialYears.length > 0 && (
                                  <Typography className={classes.financialperson}>
                                    Financial Statements
                                  </Typography>
                                )}
                                {validFinancialYears?.map(({ financial_year, statement_link }) => {
                                  const isClickable = Boolean(statement_link);
                                  return (
                                    <Tooltip
                                      key={financial_year}
                                      title={
                                        isClickable
                                          ? ''
                                          : 'File is being prepared, link will be active soon'
                                      }
                                    >
                                      <Button
                                        className={classes.yearTab}
                                        onClick={() => {
                                          if (isClickable) {
                                            const baseUrl = window.location.origin;
                                            window.open(`${baseUrl}${statement_link}`, '_blank');
                                          }
                                        }}
                                        style={{
                                          cursor: isClickable ? 'pointer' : 'default',
                                          opacity: isClickable ? 1 : 0.7
                                        }}
                                        // disabled={!isClickable}
                                      >
                                        <Typography className={classes.yearTabTxt}>
                                          {financial_year}
                                        </Typography>
                                      </Button>
                                    </Tooltip>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                              }}
                            >
                              <Typography className={classes.investorsName}>
                                Data Not Available
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid style={{ padding: '10px' }} xs={4}>
                      <div
                        style={{
                          border: '1px solid #E7E7E7',
                          borderRadius: '8px',
                          background: '#FFFFFF',
                          height: '272px',
                          padding: '10px 14px',
                          display: 'flex'
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            paddingTop: '5px',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <Box>
                            <Button
                              className={
                                selectedInvestorsTabsTab === 'Key Institutional Investors'
                                  ? classes.selected
                                  : classes.unSelected
                              }
                              onClick={() =>
                                handleInvestorsTabsClick('Key Institutional Investors')
                              }
                            >
                              <Typography
                                className={
                                  selectedInvestorsTabsTab === 'Key Institutional Investors'
                                    ? classes.selectedText
                                    : classes.unSelectedText
                                }
                              >
                                {' '}
                                Institutional Investors{' '}
                              </Typography>
                            </Button>
                            <Button
                              className={
                                selectedInvestorsTabsTab === 'Key Angel Investors'
                                  ? classes.selected
                                  : classes.unSelected
                              }
                              onClick={() => handleInvestorsTabsClick('Key Angel Investors')}
                            >
                              <Typography
                                className={
                                  selectedInvestorsTabsTab === 'Key Angel Investors'
                                    ? classes.selectedText
                                    : classes.unSelectedText
                                }
                              >
                                {' '}
                                Angel Investors{' '}
                              </Typography>
                            </Button>
                          </Box>
                          {selectedInvestorsTabsTab === 'Key Institutional Investors' &&
                            renderInstitutionalInvestors()}
                          {selectedInvestorsTabsTab === 'Key Angel Investors' &&
                            renderAngelInvestors()}
                        </Box>
                      </div>
                    </Grid>
                    <Grid style={{ padding: '10px' }} xs={8}>
                      <div
                        style={{
                          border: '1px solid #E7E7E7',
                          borderRadius: '8px',
                          background: '#FFFFFF',
                          height: '272px',
                          padding: '10px 14px'
                        }}
                      >
                        <div className={classes.cardHeadding}>
                          <IconButton style={{ paddingLeft: '5px' }}>
                            <DashBoardFundingIcon />
                          </IconButton>
                          <Typography className={classes.typographyKey}>Funding</Typography>
                        </div>
                        <div
                          style={{
                            height: '240px'
                          }}
                          className={classes.noScrollbar}
                        >
                          {investments && investments.length > 0 ? (
                            renderFundingMembers()
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '272px'
                              }}
                            >
                              <Typography className={classes.investorsName}>
                                Data Not Available
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid style={{ padding: '10px' }} xs={4}>
                      <div
                        style={{
                          border: '1px solid #E7E7E7',
                          borderRadius: '8px',
                          background: '#FFFFFF',
                          height: '272px',
                          padding: '10px 14px'
                        }}
                      >
                        <div className={classes.cardHeadding}>
                          <IconButton style={{ paddingLeft: '5px' }}>
                            <DashBoardCompetitorsIcon />
                          </IconButton>
                          <Typography className={classes.typographyKey}>Top Competitors</Typography>
                        </div>
                        <div
                          style={{
                            height: '240px'
                          }}
                          className={classes.noScrollbar}
                        >
                          {competitors?.map(({ name, location }) => (
                            <Card
                              sx={{
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #F0F0F0',
                                margin: '7.5px 7.5px 0px 0px',
                                borderRadius: '4px',
                                boxShadow: '0px 2px 4px 0px #0000000D',
                                background: '#FAFAFA',
                                padding: '2px'
                              }}
                              style={{ borderColor: '#F0F0F0', borderWidth: '1px', padding: '0px' }}
                              key={name}
                              elevation={0}
                            >
                              <CardActionArea
                                sx={{
                                  flexGrow: 1,
                                  display: 'flex',
                                  padding: '0px',
                                  cursor: 'auto'
                                }}
                                disableRipple
                              >
                                <CardContent
                                  sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '10px'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: 'Satoshi',
                                      fontSize: '14px',
                                      fontWeight: 700,
                                      color: '#5E5E5E'
                                    }}
                                  >
                                    {name}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontFamily: 'Satoshi',
                                      fontSize: '14px',
                                      fontWeight: 400,
                                      color: '#5E5E5E',
                                      paddingLeft: '10px',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {location}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          ))}
                          {!competitors ||
                            (competitors.length === 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: 'calc(100% - 30px)'
                                }}
                              >
                                <Typography className={classes.investorsName}>
                                  Data Not Available
                                </Typography>
                              </div>
                            ))}
                        </div>
                      </div>
                    </Grid>
                    <Grid style={{ padding: '10px' }} xs={8}>
                      <div
                        style={{
                          border: '1px solid #E7E7E7',
                          borderRadius: '8px',
                          background: '#FFFFFF',
                          height: '272px',
                          padding: '10px 14px'
                        }}
                      >
                        <div className={classes.cardHeadding}>
                          <IconButton style={{ paddingLeft: '5px' }}>
                            <DashBoardAskByzIcon />
                          </IconButton>
                          <Typography className={classes.typographyKey}>
                            Most Common Questions Asked
                          </Typography>
                        </div>
                        <div
                          style={{
                            height: '240px'
                          }}
                          className={classes.noScrollbar}
                        >
                          {companyQuestions === null ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'calc(100% - 30px)'
                              }}
                            >
                              <Typography className={classes.investorsName}>
                                Data Not Available
                              </Typography>
                            </div>
                          ) : (
                            companyQuestions?.map((question, index) => (
                              <React.Fragment key={index}>
                                <Typography
                                  className={classes.mostQuestoinAsked}
                                  onClick={(event) => handleQuestionClickDashbaord(event, question)}
                                >
                                  {question}
                                </Typography>
                                <Divider className={classes.divider} />
                              </React.Fragment>
                            ))
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ padding: '10px' }} xs={3}>
                  <div
                    style={{
                      border: '1px solid #E7E7E7',
                      borderRadius: '8px',
                      background: '#FFFFFF',
                      height: '924px',
                      padding: '10px 14px'
                    }}
                  >
                    <div className={classes.cardHeadding}>
                      <IconButton style={{ paddingLeft: '5px' }}>
                        <DashBoarNewsIcon />
                      </IconButton>
                      <Typography className={classes.typographyKey}>News</Typography>
                    </div>
                    <div style={{ height: '876px' }} className={classes.noScrollbar}>
                      <NewsFeed classes={classes} newsPageSize={10} dashBoardNews={true} />
                    </div>
                  </div>
                </Grid>
                <Grid style={{ padding: '10px' }} xs={12}>
                  <div
                    style={{
                      border: '1px solid #E7E7E7',
                      borderRadius: '8px',
                      background: '#FFFFFF',
                      padding: '10px 14px'
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className={classes.cardHeadding}>
                        <IconButton style={{ paddingLeft: '0px' }}>
                          <DashBoardTearSheetIcon />
                        </IconButton>
                        <Typography
                          className={classes.typographyLinkKey}
                          onClick={() =>
                            navigate(
                              `/report?search=${encodeURIComponent(
                                currentActiveCompany?.data?.base?.Name
                              )}`
                            )
                          }
                        >
                          Custom Reports
                        </Typography>
                      </div>
                      <div
                        className={classes.uploadButtonTextDiv}
                        onClick={() => {
                          navigate(
                            `/report?search=${encodeURIComponent(
                              currentActiveCompany?.data?.base?.Name
                            )}&action=create`
                          );
                        }}
                      >
                        <AddIconPlus />
                        <Typography className={classes.uploadButtonText}>
                          Create New Report
                        </Typography>
                      </div>
                    </div>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Button
                        sx={{
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent'
                          }
                        }}
                        onClick={() => handleScroll('left')}
                      >
                        {canScrollLeft ? <LeftArrowIcon /> : <LeftArrowHiddenIcon />}
                      </Button>
                      <Box
                        ref={containerRef}
                        className={classes.noScrollbar}
                        sx={{
                          display: 'flex',
                          whiteSpace: 'nowrap',
                          width: '100%',
                          padding: '10px'
                        }}
                      >
                        {dashBoardTemplateList.length > 0 &&
                        dashBoardTemplateList.some((item) => item?.report_name !== '') ? (
                          dashBoardTemplateList.map((item, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: item?.report_name === '' ? 'none' : 'inline-block',
                                minHeight: '60px',
                                textAlign: 'center',
                                backgroundColor: '#EAF1FE',
                                border: '1px solid #B3CDFA',
                                margin: '0px 10px',
                                borderRadius: '8px',
                                padding: '11px 10px',
                                alignContent: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                const link = `/report?search=${item?.entity_name}&report_id=${item?.report_id}`;
                                window.open(link, '_blank');
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: '#FFFFFF',
                                  border: '1px solid none',
                                  borderRadius: '8px',
                                  padding: '10px 10px',
                                  minHeight: '44px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <div className={classes.reportBox}>
                                  <Typography className={classes.templateName}>
                                    {item.report_name}
                                  </Typography>
                                  {/* <Typography className={classes.templateDataUsed}>
            {item.data_used === 'pb_data' && t('tearSheet.publicDataText')}
          </Typography> */}
                                </div>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <div
                            className={classes.addReport}
                            onClick={() => {
                              navigate(
                                `/report?search=${encodeURIComponent(
                                  currentActiveCompany?.data?.base?.Name
                                )}&action=create`
                              );
                            }}
                          >
                            <Typography className={classes.templateName}>
                              No reports exist for this company. Click to create a new report
                            </Typography>
                          </div>
                        )}
                      </Box>
                      <Button
                        sx={{
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent'
                          }
                        }}
                        onClick={() => handleScroll('right')}
                      >
                        {canScrollRight ? <RightArrowIcon /> : <RightArrowHiddenIcon />}
                      </Button>
                    </Box>
                  </div>
                </Grid>
                <Grid style={{ padding: '10px' }} xs={12}>
                  <div
                    style={{
                      border: '1px solid #E7E7E7',
                      borderRadius: '8px',
                      background: '#FFFFFF',
                      padding: '10px 14px'
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className={classes.cardHeadding}>
                        <IconButton style={{ paddingLeft: '0px' }}>
                          <DashBoardMyDocIcon />
                        </IconButton>
                        <Typography
                          className={classes.typographyLinkKey}
                          onClick={() => navigate(`/document-upload/manage`)}
                        >
                          My Documents
                        </Typography>
                      </div>
                      <div
                        className={classes.uploadButtonTextDiv}
                        onClick={() => {
                          navigate(`/document-upload/upload`);
                        }}
                      >
                        <AddIconPlus />
                        <Typography className={classes.uploadButtonText}>
                          Upload Documents
                        </Typography>
                      </div>
                    </div>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {companyDocList.length > 0 ? (
                        <>
                          <Button
                            sx={{
                              backgroundColor: 'transparent',
                              '&:hover': {
                                backgroundColor: 'transparent'
                              }
                            }}
                            onClick={() => handleScrollDoc('left')}
                          >
                            {canScrollLeftDoc ? <LeftArrowIcon /> : <LeftArrowHiddenIcon />}
                          </Button>
                          <Box
                            ref={containerRefDoc}
                            className={classes.noScrollbar}
                            sx={{
                              display: 'flex',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              padding: '10px'
                            }}
                          >
                            {companyDocList.length > 0 &&
                              companyDocList.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'inline-block',
                                    minHeight: '40px',
                                    textAlign: 'center',
                                    border: '1px solid #E8E8E8',
                                    margin: '0px 10px',
                                    borderRadius: '8px',
                                    padding: '11px 10px',
                                    alignContent: 'center',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => window.open(item?.source_link, '_blank')}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: '#FFFFFF',
                                      border: '1px solid none',
                                      borderRadius: '8px',
                                      padding: '10px 10px'
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography className={classes.docFileName}>
                                        {item.file_name}.{item.file_format}
                                      </Typography>
                                    </div>
                                  </Box>
                                </Box>
                              ))}
                          </Box>
                          <Button
                            sx={{
                              backgroundColor: 'transparent',
                              '&:hover': {
                                backgroundColor: 'transparent'
                              }
                            }}
                            onClick={() => handleScrollDoc('right')}
                          >
                            {canScrollRightDoc ? <RightArrowIcon /> : <RightArrowHiddenIcon />}
                          </Button>
                        </>
                      ) : (
                        <NoFilesUploadedTableComp />
                      )}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </div>
    </>
  );
};

export default DashBoard;
